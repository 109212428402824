import config from "../config"

export default function ApiServices() {


    return {

        async getWhiteList(collection: string) {
            var params = { collection: collection }


            return fetch(config.api.url + config.api.get_whitelist + "?" + new URLSearchParams(params), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getDetailNFT(id: string) {
            var params = { doc_id: id }


            return fetch(config.api.url + config.api.get_detail_nft + "?" + new URLSearchParams(params) + "&element=true", {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },

        async getNFTGallery(id:string, personality:string, elemental:string, isFilter:boolean){
            var params = {};
            if(isFilter){
                params = { name_id: id, limit: "100", personality_type: personality, elemental: elemental };
            }else{
                params = { name_id: id, limit: "100" };
            }
           

            return fetch(config.api.url + config.api.get_nft_gallery + "?" + new URLSearchParams(params) , {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },

        async saveWhitelist(collection, address) {
            var params = { collection: collection, address: address }

            return fetch(config.api.url + config.api.save_whitelist, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
            ).then(res => res.json())
        },
        async getItems(types: string, classify: string) {
            var params = { classify: classify, type: types }


            return fetch(config.api.url2 + config.api.get_items + "?" + new URLSearchParams(params), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async checkWhiteList(collection: string, address: string) {
            var params = { collection: collection, address: address }


            return fetch(config.api.url + config.api.check_whitelist + "?" + new URLSearchParams(params), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async checkWhiteListPrivateSale(collection: string, address: string) {
            var params = { collection: collection, address: address }


            return fetch(config.api.url + config.api.check_whitelist + "?" + new URLSearchParams(params), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getSignature(useraddress: string) {


            return fetch("https://mondayclub.io/api/signature", {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(useraddress)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        ,
        async sendMessageToLINE(message: string) {
            var params = { message: message }

            return fetch(config.api.url + "line-notify", {
                method: 'POST', headers: {
                    'Authorization': config.api.line_authorization,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getDetailCard(id: string, pinata_url: string) {
            var params = { token_id: id, pinata_url: pinata_url }
            return fetch(config.api.url + config.api.get_nft_reveal + "?" + new URLSearchParams(params), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async postDetailCard(id: number, reveal: boolean) {
            var params = { token_id: id, reveal: reveal }
            return fetch(config.api.url + config.api.post_nft_reveal , {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
    }
}