import HeaderComponent from "../header";
import "./index.scss"
import "../card-styles/cards.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import ROADMAP_IMG from "../../assets/images/roadmap.png";
import NFTGROUP from "../../assets/images/nft-group-gif.gif";
import ROADMAP_HEADER_IMG from "../../assets/images/roadmap-header.png";
import ROADMAP_TODO_IMG from "../../assets/images/roadmap-todo.png";
import ROADMAP_INPROGRESS_IMG from "../../assets/images/roadmap-inprogress.png";
import ROADMAP_DONE_IMG from "../../assets/images/roadmap-done.png";
import FooterComponent from "../footer";
import React, { useRef, useState } from "react";
import UtilityService from "../../utils/utility";
import TOP1 from "../../assets/images/top1.png";
import SOLD_OUT_IMG from "../../assets/images/sold-out.jpg";
import BOTTOM1 from "../../assets/images/bottom1.png";
import STARSEC2 from "../../assets/images/star-section-2.png";
import BONUS_1_IMG from "../../assets/images/bonus-1.png";
import BONUS_2_IMG from "../../assets/images/bonus-2.png";
import BONUS_3_IMG from "../../assets/images/bonus-3.png";
import BONUS_4_IMG from "../../assets/images/bonus-4.png";
import BONUS_5_IMG from "../../assets/images/bonus-5.png";
import TIMELINE_IMG from "../../assets/images/timeline.png";
import GIVEAWAY_IMG from "../../assets/images/giveaway-img.png";
import PRIVATE_SALE_IMG from "../../assets/images/private-sale-img.png";
import TIMELINE_MOBILE_IMG from "../../assets/images/timeline-mobile.png";
import MYSTERY_CARD_IMG from "../../assets/images/mystery-card.png";
import IMGWORDING from "../../assets/images/monday-squad.png";
import PSF_GIF from "../../assets/images/psn-gif.gif";
import QUESTION_IMG from "../../assets/images/question.png";
import PRIVATE_SALE_IMG_2 from "../../assets/images/public-sale.png";
import { useWindowWidth } from "@react-hook/window-size";
import config from "../../config";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import SHOW_CARD_VIDEO from "../../assets/images/mystery-card.mp4";
import PopupAds from "../../modal/popup-ads";
import { useDisclosure } from "@chakra-ui/react";
import VIDEO_SNEAK_PEEK from "../../assets/images/vdo-sneak-peek.mp4";
import ReactPlayer from "react-player";
import { ethers } from "ethers";
import Web3Service from "../../services/web3-service";
import useGlobalState from "@vighnesh153/use-global-state";
import ApiServices from "../../services/api";
import nft from "../../abi/nft";
import { Attribute, PinataModel } from "../../model/pinata";
import CHECK_CARD from "../../assets/images/check_card.jpg";
import PopupDesktop from "../../modal/use-desktop";
import LoadingMessage from "../../modal/loading-message";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const LandingComponent = () => {
    let navigate = useNavigate();
    const [isClick, setClickCard] = useState(false);
    const [isError, setError] = useState(false);
    const [pinataModel, setPinataModel] = useState<any>([]);
    const [ownerOf, setOwnerOf] = useState(false);
    const [account, setAccount] = useGlobalState('account', "");
    const [isLoadingChecking, setLoadingChecking] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoadingConnect, setLoadingConnect] = useState(false);
    const [isNoNFT, setNoNFT] = useState(false);
    const [isPopup, setPopup] = useState(false);
    const myCountDown = useRef(null)
    const [errorMessage, setErrorMessage] = useState("");
    const myCountDown2 = useRef(null)
    const [isReadmore, setReadmore] = useState(false);
    const title = "LANDING";
    const width = useWindowWidth();
    let providers: ethers.providers.Web3Provider;
    const cards = useRef<HTMLDivElement>(null);
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false
    React.useEffect(() => {
        onOpen();
    }, [])
    const executeScroll = async () => {
        UtilityService().clickSendEvent("Click", title, "How to get");
        scrollToRef(myCountDown)
    }

    const executeScrollSquad = async () => {
        UtilityService().clickSendEvent("Click", title, "What is");
        scrollToRef(myCountDown2)
    }
    const openDiscordToken = () => {
        UtilityService().clickSendEvent("Click", title, "JOIN DISCORD");
        window.open(config.link_social.discord, "_blank");

    }

    const clickBuyNFT = () => {
        UtilityService().clickSendEvent("Click", title, "Buy NFT");
        window.open("https://opensea.io/collection/monday-club-nft", "_blank");
    }

    const clickMint = () => {
        UtilityService().clickSendEvent("Click", title, "Mint")
    }

    const clickPersonality = () => {
        UtilityService().clickSendEvent("Click", title, "Personality")
        window.open(config.app.personality, "_blank");
    }

    const clickReadmore = () => {
        setReadmore(true);
    }
    const clickGiveaway = () => {
        UtilityService().clickSendEvent("Click", title, "Giveaway ")
        navigate("/giveaway", { replace: false });
    }

    const clickSale = () => {
        UtilityService().clickSendEvent("Click", title, "Giveaway ")
        navigate("/sale", { replace: false });
    }

    const clickAccount = () => {
        UtilityService().clickSendEvent("Click", title, "REVEAL NOW ")
        navigate("/account", { replace: false });
    }
    const clickGallery = () => {
        UtilityService().clickSendEvent("Click", title, "VIEW COLLECTION ")
        navigate("/gallery", { replace: false });
    }

    const connectwallet = async () => {
       

        if (isMobile || isTablet) {
            setPopup(true);
            onOpen();
        } else {
            if (window.ethereum) {
                providers = new ethers.providers.Web3Provider(window.ethereum)
                setLoadingConnect(true);
                const walletAddress = await Web3Service().connectWallet();
                setAccount(walletAddress);
                setLoadingConnect(false);




                const isNetwork = await Web3Service().checkNetwork();
                if (isNetwork) {
                    checkOwner();

                } else {
                    Web3Service().handleNetworkChangeRinkeby();
                    window.ethereum.on('chainChanged', function (network: any) {
                        if (network === config.web3.eth.network) {
                            checkOwner();
                        }
                    });
                }
            } else {
                alert("Please Install Metamask");
            }
        }

    }

    const checkOwner = async () => {
        setLoadingChecking(true);
        console.log("checking");
        if (providers) {


            try {
                await providers.send("eth_requestAccounts", []);
                const signerMetaMask = providers.getSigner();
                const userAddress = await signerMetaMask.getAddress();

                console.log(signerMetaMask);
                const contractMCN = new ethers.Contract(config.web3.MCNFT.address, nft.ABI, signerMetaMask);
                try {
                    let isStop = false;
                    let startIndex = 0;
                    let opened = 0;
                    let unopened = 0;
                    let isFailed = false;
                    while (!isStop) {
                        const tx = await contractMCN.tokenOfOwnerByIndex(userAddress, startIndex)
                            .catch((error: any) => {
                                console.log(error);
                                if (!ownerOf) {
                                    setLoadingChecking(false);
                                }
                            })

                        if (tx) {
                            isFailed = true;
                            console.log(tx.toString());
                            const tx2 = await contractMCN.tokenURI(tx.toString())
                                .catch((error: any) => {

                                    isStop = true;
                                    console.log(error);
                                })

                            if (tx2) {
                                console.log(tx2);
                                UtilityService().clickSendEvent("View", title, "Own card  " + userAddress);

                                startIndex++;


                                ApiServices().getDetailCard(tx.toString(), tx2).then((res: any) => {
                                    console.log(res);
                                    setOwnerOf(true);
                                    setPinataModel(pinataModel => [...pinataModel, res]);

                                })


                            }



                        } else {
                            if (isFailed) {
                                setLoadingChecking(false);
                            }
                            isStop = true;
                            setNoNFT(true);
                        }

                    }

                } catch (err) {
                    setLoadingChecking(false);
                    onOpen();
                    setError(true);
                    setErrorMessage("Oops! Something went wrong.");
                }

            } catch {
                setLoadingChecking(false);
                console.log("User rejected the request.")
                onOpen();
                setError(true);
                setErrorMessage("User rejected the request.");
            }
        } else {
            setLoadingChecking(false);
        }
    }


    const clickCard = (id: string, pinata: PinataModel) => {

        navigate("/detail-nft/" + pinata.token_id, { state: { pinata }, replace: false })
    }



    return (
        <div className="container-fluid container-control">
            <HeaderComponent></HeaderComponent>
            {
                isPopup ? <PopupDesktop onOpen={onOpen} isOpen={isOpen} onClose={onClose} ></PopupDesktop> :
                    null

            }
            {/* <PopupAds onOpen={onOpen} isOpen={isOpen} onClose={onClose} ></PopupAds> */}

            <div className="container-control d-flex flex-column">

                <div className="section-1-control">
                    <img src={TOP1} className="img-top"></img>

                    <div className="container">
                        <img src={IMGWORDING} className="img-wording mx-auto"></img>
                        <div className="grounded-radiants d-flex flex-column">
                            {
                                !account && <img src={CHECK_CARD} className="img-cover-check-card"></img>
                            }

                            <div className="d-flex flex-column gradient-control">
                                <section className="cards">
                                    {
                                        isLoadingChecking ?
                                            <div className="d-flex mx-auto loading-nft-control">
                                                <span className="text-loading">Loading .... </span>
                                            </div>
                                            :

                                            ownerOf && isNoNFT ?
                                                pinataModel.map((value: PinataModel, index: any) => {

                                                    return (
                                                        value.attributes.map((item: Attribute, index2: any) => {

                                                            if (item.trait_type === "POWER OF DAY") {

                                                                if (item.value.includes('MOON')) {


                                                                    return (
                                                                        <div className="img-card-control animated"
                                                                            onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card moon " >
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                } else if (item.value.includes('WOOD')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card wood ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else if (item.value.includes('EARTH')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card earth ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else if (item.value.includes('FIRE')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card fire ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else if (item.value.includes('METAL')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card metal ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else if (item.value.includes('SUN')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card sun ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else if (item.value.includes('WATER')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card water ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }

                                                                else if (item.value.includes('WOOD')) {

                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card wood ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>


                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                            <div className="card metal ">
                                                                            </div>
                                                                            <video className="img-nft-in-card" autoPlay loop>
                                                                                <source src={value.animation_url} type="video/mp4"></source>
                                                                            </video>
                                                                            <span className="text-nft-in-card">{value.name}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            }


                                                        })

                                                    )

                                                }) :

                                                isNoNFT && pinataModel.length > 0 ?
                                                    <div className="no-nft-control">
                                                        <span className="text-no-nft">Sorry, You're not our SQUAD.</span>
                                                        <button className="button-active" onClick={clickBuyNFT}>Buy NFT Here</button>
                                                    </div> : null
                                    }

                                </section>

                                {
                                    !account && <button className="button-connect-wallet mx-auto" onClick={connectwallet}>Check your card now</button>
                                }


                                {/* <Countdown date={Date.parse(config.countdown_reveal)} renderer={rendererPrivateSale} /> */}
                                {/* <span className="mint-date-text">YOUR NFT WILL LOOK LIKE</span>
                                <ReactPlayer url={VIDEO_SNEAK_PEEK}
                                    loop={true}
                                    playing={true}
                                    className="video-sneak-peek"
                                    config={{
                                        file: {
                                            attributes: {
                                                autoPlay: true,
                                                muted: true
                                            }
                                        }
                                    }}
                                    playsinline></ReactPlayer> */}

                            </div>

                        </div>


                    </div>


                    <div className="bottom-control d-flex flex-column">
                        <img src={BOTTOM1} className="img-bottom"></img>
                        <img src={NFTGROUP} className="img-nft-group"></img>
                        <div className="d-flex flex-column img-bottom-2 " >
                            <div className="d-flex flex-column text-section-2-control">


                                <div className="container d-flex flex-column">
                                    <img src={QUESTION_IMG} className="question-img mx-auto"></img>
                                    <span className="text-brown-desc" ref={myCountDown2}>Monday Squad will bring back the fun into the boring 9 to 5 routine.
                                        Monday Squad is all about being part of something bigger than yourself.
                                        Be a part of Monday Squad & Monday Club to make Monday great again!
                                        <br></br><br></br>
                                        Get our Monday Squad NFTs and see the vast possibilities of
                                    </span>
                                    <span className="text-brown-header">who you can really be. </span>
                                    <span className="text-white-header">Items Classify</span>
                                </div>
                                <div className="marquee-control">
                                    <div className="classify-control">
                                        <span className="text-control common-color">COMMON</span>
                                        <span className="text-control epic-color">EPIC</span>
                                        <span className="text-control rare-color">RARE</span>
                                        <span className="text-control legend-color">LEGENDARY</span>
                                        <span className="text-control common-color">COMMON</span>
                                        <span className="text-control epic-color">EPIC</span>
                                        <span className="text-control rare-color">RARE</span>
                                        <span className="text-control legend-color">LEGENDARY</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

                <div className="section-2-control">
                    <span className="text-brown-header mx-auto">Monday Squad's Bonus!!!</span>
                    <div className="container grid-bonus-control">
                        <div className="d-flex flex-column">
                            <img src={BONUS_1_IMG} className="bonus-img mx-auto"></img>
                            <span className="text-bonus mx-auto">Rarity-based
                                Staking</span>
                        </div>
                        <div className="d-flex flex-column">
                            <img src={BONUS_2_IMG} className="bonus-img mx-auto"></img>
                            <span className="text-bonus mx-auto">Item Airdrops</span>
                        </div>
                        <div className="d-flex flex-column">
                            <img src={BONUS_3_IMG} className="bonus-img mx-auto"></img>
                            <span className="text-bonus mx-auto">Real World
                                Utilities</span>
                        </div>
                        <div className="d-flex flex-column">
                            <img src={BONUS_4_IMG} className="bonus-img mx-auto"></img>
                            <span className="text-bonus mx-auto">Exclusive Space
                                & Activities</span>
                        </div>
                        <div className="d-flex flex-column bonus-last-row">
                            <img src={BONUS_5_IMG} className="bonus-img mx-auto"></img>
                            <span className="text-bonus mx-auto">Right to mint
                                land <br className="d-inline d-md-none"></br>in the near
                                future</span>
                        </div>

                    </div>
                    <div className="container grid-control">

                        <div className="d-flex flex-column my-auto  order-1 order-lg-0  grid-left-control">
                            <img src={STARSEC2} className="img-star  bounce-animation"></img>
                            <span className="text-power-day">16 Personalities x Power of Days</span>
                            <span className="text-16-psn">Do not let yourself be stopped by what people say
                                about your personality type!</span>
                            <span className="text-brown-desc">Monday Squad NFTs reflects the whole spectrum of personalities because
                                we truly believe that the personality of an individual is not fixed but is switched depending on different situations.
                                Start now by getting one that match your current personality and prepare to be amazed by what the status of the NFT
                                you get will lead you to.</span>
                            {/* <div className="d-block text-left">
                                <button className="button-active">Take a Quiz</button>
                            </div> */}
                            <span className="text-16-psn">Don’t know your personalities?
                                <br></br>
                            </span>
                            <button className="button-brown-big" onClick={clickPersonality}>Start Test Now</button>
                        </div>
                        <div className="d-flex my-auto order-0 order-lg-1">

                            <img src={PSF_GIF} className="psn-gif mx-auto"></img>
                        </div>
                    </div>
                </div>
                <div className="section-3-control d-flex flex-column" >
                    <div className="container grid-control">
                        <div className="d-flex" ref={myCountDown}>

                            <img src={MYSTERY_CARD_IMG} className="mystery-card-img my-auto mx-auto bounce-animation"></img>
                        </div>
                        <div className="d-flex flex-column my-auto"  >
                            <span className="text-total">1,200</span>
                            <span className="text-mystery-box">MYSTERY CARD</span>
                            <span className="text-sale-in">for MONDAY SQUAD:</span>
                            <span className="text-mystery-box font-nanum">NOW HIRING</span>
                        </div>

                    </div>
                    <div className="container">

                        {
                            isMobile || isTablet ? <img src={TIMELINE_MOBILE_IMG} className="timeline-img"></img>
                                : <img src={TIMELINE_IMG} className="timeline-img"></img>

                        }

                    </div>



                    {/* <div className="d-block text-center">
                        <button className="button-disable">Mint Now</button>
                    </div> */}
                    <div className="container roadmap-control">

                        <img src={ROADMAP_HEADER_IMG} className="roadmap-header"></img>
                        <div className="roadmap-grid">

                            <div className="roadmap-border mx-auto d-flex flex-column">
                                <img src={ROADMAP_TODO_IMG} className="roadmap-todo-img"></img>

                                <ul className="ui-control">
                                    <li className="text-li">
                                        New collections of Branded Rare avatar NFT with more partners
                                    </li>
                                    <li className="text-li">
                                        Launch DeFI Investment Pool Access V2
                                    </li>
                                    <li className="text-li">
                                        Launch DeFi Investment Pool Access V3
                                    </li>
                                    <li className="text-li">
                                        Launch Monday Squad NFT Staking System
                                    </li>

                                    <li className="text-li">
                                        2nd Collection of Monday Squad: Team-up Launch MetaOffice Where Companies Open their offices in our Metaverse

                                    </li>

                                    <li className="text-li">
                                        Metaverse integration with other web 2.0 services
                                    </li>
                                    <li className="text-li">
                                        Launch second phase of payroll protocol with Flexi Benefits
                                    </li>

                                    <li className="text-li">
                                        Listing $MONDAY Token on Centralized Exchanges to give access to general public
                                    </li>

                                    <li className="text-li">
                                        Link IB Tokens to Centralized Exchanges and give access to web 2.0 users to invest/trade on DeFi Farms
                                    </li>




                                </ul>
                            </div>
                            <div className="roadmap-border mx-auto d-flex flex-column">
                                <img src={ROADMAP_INPROGRESS_IMG} className="roadmap-todo-img"></img>
                                <ul className="ui-control">
                                    <li className="text-li">
                                        Sales of First Collection of <strong>Monday Squad</strong>: NOW HIRING
                                    </li>
                                    <li className="text-li">
                                        Launch First phase of Payroll protocol
                                    </li>
                                    <li className="text-li">
                                        Utilize Cross-Chain protocol to go beyond just BSC
                                    </li>
                                    <li className="text-li">
                                        Launch Branded Rare Avatar NFT with Melos.Studio
                                    </li>

                                </ul>

                            </div>
                            <div className="roadmap-border mx-auto d-flex flex-column">
                                <img src={ROADMAP_DONE_IMG} className="roadmap-todo-img"></img>

                                <ul className="ui-control">
                                    <li className="text-li">
                                        Launched Branded Rare Avatar NFT with Partners (GQ Thailand, Central Group, True VirtualWorld)
                                    </li>
                                    <li className="text-li">
                                        Listed $MONDAY LPs on PancakeSwap
                                    </li>
                                    <li className="text-li">
                                        Audited Auto Compound Protocol by Inspex
                                    </li>
                                    <li className="text-li">
                                        Launched Auto Compound Protocol (DeFi Investment Pool Access V1)
                                    </li>
                                    <li className="text-li">
                                        Launched $MONDAY Single Token and LP Staking
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>



            </div>


            <FooterComponent isBlack={false}></FooterComponent>
        </div>
    )
}

export default LandingComponent;

