

import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import "./popup-reveal.scss";
import "./get-card.scss";
import CARD_FLIP_ANIMATION from "../assets/images/card-flip-animation.mp4";
import ReactPlayer from "react-player";

const PopupReveal = ({ isOpen, onOpen, onClose, image_path, animation_url }: any) => {
    const [size, setSize] = React.useState('xxl')
    const [imagePath, setImagePath] = React.useState(CARD_FLIP_ANIMATION);
    const [animatePath, setAnimatePath] = React.useState(animation_url);
    const [isOpenNFT, setisOpenNFT] = React.useState(false)
    const [isLoop, setIsLoop] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    React.useEffect(() => {
        console.log(animatePath);
    }, [])

    const onEndedPlayer = () => {
        if (!isOpenNFT) {
            setIsReady(true);
            setIsLoop(true);
            setisOpenNFT(true);
            console.log("End");
        }


    }

    const onReadyPlayer = () => {
        console.log("Ready");
    }


    return (

        <>

            <Modal closeOnOverlayClick={false} size={size} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='blackAlpha.800'
                    backdropFilter='blur(10px)' />

                <div className='get-card popup-reveal '>
                    <ModalContent className="popup-reveal">
                        {/* <ModalHeader>Modal Title</ModalHeader> */}
                        <ModalBody className="d-flex flex-column popup-reveal">

                            {
                                !isOpenNFT ? <ReactPlayer url={imagePath}
                                    playing={true}
                                    className="video-mystery-card"
                                    onEnded={onEndedPlayer}
                                    playsinline></ReactPlayer> : null
                            }


                            <div className={
                                !isReady ? "d-none" : "d-flex"
                            } >
                                <ReactPlayer url={animatePath}
                                    loop={isLoop}
                                    playing={isOpenNFT}
                                    className="video-mystery-card"
                                    onReady={onReadyPlayer}
                                    playsinline></ReactPlayer>
                            </div>




                            <div className="d-block mx-auto">
                                <button className="button-brown" onClick={onClose}>Close</button>

                            </div>



                        </ModalBody>


                    </ModalContent>
                </div>
            </Modal>

        </>
    )
}

export default PopupReveal;