/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { DetailNFTModel } from "../../model/detail-nft.model";
import ApiServices from "../../services/api";
import IDSALE from "../../assets/json/id-sale.json";
import "./gallery.scss";
import HeaderComponent from "../header";
import FooterComponent from "../footer";
import { GalleryNFT, NFTGalleryItem } from "../../model/gallery-nft";

import PSN_HIGH_IMG from "../../assets/images/psn-high.png";
import PSN_MEDIUM_IMG from "../../assets/images/psn-medium.png";
import PSN_LOW_IMG from "../../assets/images/psn-low.png";

import ELEM_HIGH_IMG from "../../assets/images/elem-high.png";
import ELEM_MEDIUM_IMG from "../../assets/images/elem-medium.png";
import ELEM_LOW_IMG from "../../assets/images/elem-low.png";

import TEXT_HIGH_IMG from "../../assets/images/high.png";
import TEXT_MEDIUM_IMG from "../../assets/images/medium.png";
import TEXT_LOW_IMG from "../../assets/images/low.png";
import { Select } from "@chakra-ui/react";
import UtilityService from "../../utils/utility";
import DropdownComponent from "../items/dropdown";
import { ethers } from "ethers";
import NAME_ID from "../../assets/json/name-id.json";
import ID_NAME from "../../assets/json/id-name.json";
import config from "../../config";
import nft from "../../abi/nft";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PinataModel } from "../../model/pinata";

const GalleryComponent = () => {
    const [nextID, setNextID] = useState("");
    const [persnality, setPersonality] = useState("");
    const [elemental, setElemental] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [propRes, setPropres] = useState<any>([]);
    const [isHideLoadmore, setHideLoadmore] = useState(false);
    const [pinataModel, setPinataModel] = useState<PinataModel>(Object);
    const [noResult, setNoResult] = useState(false);
    let id = "";
    let navigate = useNavigate();
    const title = "Gallery";
    let type = (new URLSearchParams(window.location.search)).get("type")
    let providers: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider;
    React.useEffect(() => {
        // getDetailItem();

        if (type) {
            setPersonality(type + "");
            setIsFilter(true);
            callAPI("", type, elemental, true);
        } else {
            callAPI("", persnality, elemental, isFilter);
        }

        runTokenID();
    }, [])

    const getDetailItem = async () => {


        // callAPI(IDSALE[i]);

        IDSALE.map((value: any, index: any) => {
            ApiServices().getDetailNFT(value).then((res: any) => {
                // let newAnswerArray = [...propRes];
                // console.log(res);
                // newAnswerArray.push(res);
                // console.log(newAnswerArray);
                // setPropres(newAnswerArray)
                res["id"] = value;
                res["real_id"] = index + 200;
                setPropres(propRes => [...propRes, res]);
                // i += 1;
                // callAPI(IDSALE[i]);
            })
        })



    }
    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }
    const callAPI = (value, persnality, elemental, isFilter) => {

        ApiServices().getNFTGallery(value, persnality, elemental, isFilter).then((res: GalleryNFT) => {
            // let newAnswerArray = [...propRes];
            // console.log(res);
            // newAnswerArray.push(res);
            // console.log(newAnswerArray);
            // setPropres(newAnswerArray)
            console.log(res);
            // 

            if (res.nft.length === 0) {
                setHideLoadmore(true);
            } else {
                setHideLoadmore(false);
            }

            res.nft.map((res: NFTGalleryItem, index: any) => {
                ID_NAME.map((res2: any, index: any) => {


                    if (res.name_id === res2) {
                        res.link_opensea = config.app.opensea_prefix + index;
                        setPropres(propRes => [...propRes, res]);
                    }
                })

            })
            setNextID(res.nft[res.nft.length - 1].name_id)

            // res["id"] = value;
            // setPropres(propRes => [...propRes, res]);
            // i += 1;
            // callAPI(IDSALE[i]);
            if (res.nft.length > 0) {
                setNoResult(false)
            } else {
                if (propRes.length === 0) {
                    setNoResult(true);
                } else {
                    setNoResult(false)
                }
            }

        }, error => {
            if (propRes.length === 0) {
                setNoResult(true);
            } else {
                setNoResult(false)
            }
        })

    }

    const clickLoadmore = () => {
        console.log(nextID);
        console.log(persnality);
        UtilityService().clickSendEvent("Click", title, "Load more");
        callAPI(nextID, persnality, elemental, isFilter)
    }

    const clickBuyNow = (url, type) => {
        UtilityService().clickSendEvent("Click", title, "Buy " + type);
       window.open(url, "_blank")
    }

    const onHandleChange = (e) => {
        setPropres([])
        setNextID("");
        if (e.target.value === "") {
            console.log("all");
            setPersonality("");

            UtilityService().clickSendEvent("Click", title, "Select Personality all");
        } else {
            setIsFilter(true);
            setPersonality(e.target.value);
            UtilityService().clickSendEvent("Click", title, "Select Personality " + e.target.value);

        }

        if (e.target.value !== "" || elemental !== "") {
            setIsFilter(true);
            callAPI("", e.target.value, elemental, true)
        } else {
            callAPI("", e.target.value, elemental, false)
            setIsFilter(false);
        }




    }
    const onHandleChangeElement = (e) => {
        setPropres([])
        setNextID("");
        if (e.target.value === "") {
            console.log("all");
            setElemental("");
            UtilityService().clickSendEvent("Click", title, "Select Elemental all");
        } else {
            setElemental(e.target.value);
            UtilityService().clickSendEvent("Click", title, "Select Elemental " + e.target.value);
        }
        if (persnality !== "" || e.target.value !== "") {
            setIsFilter(true);
            callAPI("", persnality, e.target.value, true)
        } else {
            callAPI("", persnality, e.target.value, false)
            setIsFilter(false);
        }


    }

    const clickItem = async (id: string) => {


        ID_NAME.map((res: any, index: any) => {


            if (id === res) {
                console.log("Match")
                UtilityService().clickSendEvent("Click", title, "More Detail " + index);
                navigate("/detail-nft/" + index, { replace: false })
            }
        })

    }

    const runTokenID = async () => {
        // let arrayString : String[] = [];
        // NAME_ID.map((res:any, index:any) => {
        //     let startName = res;
        //     if (startName > 9) {
        //         id = pad(startName, 5);
        //     } else if (startName > 99) {
        //         id = pad(startName, 4);
        //     } else if (startName > 999) {
        //         id = pad(startName, 3);
        //     } else {
        //         id = pad(startName, 5);
        //     }

        //     arrayString.push("#"+id);
        //     console.log(arrayString);

        // })


        // if(window.ethereum){
        //     providers = new ethers.providers.Web3Provider(window.ethereum)
        // }else{
        //     providers = new ethers.providers.JsonRpcProvider(config.web3.eth.rpc);
        // }

        //     const contractMCN = new ethers.Contract(config.web3.MCNFT.address, nft.ABI, providers);
        //     let arraySaveObject : String[] = [];
        //     try {
        //         for(let i =452 ;i <2000 ; i++){
        //             const tx = await contractMCN.tokenURI(i);
        //             // console.log(tx);

        //             arraySaveObject.push(tx.toString().split("https://mondayclub.mypinata.cloud/ipfs/QmTR2DfixxqWZfSAeAnKGGMTxQK1ZuBTi1GGBrgoZ1gtLw/")[1].split(".json")[0]);
        //             // console.log(i + " == " + tx.toString().split("https://mondayclub.mypinata.cloud/ipfs/QmddpLqfrgbX3wkuVBxYs1WkZb2y86f75dwoKxsvQA2QQL/")[1].split(".json")[0])
        //             console.log(arraySaveObject);
        //             // console.log(i + " == " + tx.toString().split("https://mondayclub.mypinata.cloud/ipfs/QmTR2DfixxqWZfSAeAnKGGMTxQK1ZuBTi1GGBrgoZ1gtLw/")[1].split(".json")[0])
        //         }


        //     }catch{

        //     }
    }
    return (
        <>
            <div className="container-fluid container-control ">
                <HeaderComponent></HeaderComponent>
                <div className="gallery">
                    <div className="d-flex flex-column">
                        <div className="page-control"></div>
                        <span className="text-header">Gallery</span>
                        <div className="filter-control">
                            <span className="text-filter">Filter : </span>
                            <Select placeholder='Select Personality' onChange={(e) => onHandleChange(e)} className="border-dropdown" icon={<DropdownComponent />}>
                                <option value=''>ALL</option>
                                <option value='ENFJ'>ENFJ</option>
                                <option value='ENFJ'>ENFJ</option>
                                <option value='ENFP'>ENFP</option>
                                <option value='ENTJ'>ENTJ</option>
                                <option value='ENTP'>ENTP</option>
                                <option value='ESFJ'>ESFJ</option>
                                <option value='ESFP'>ESFP</option>
                                <option value='ESTJ'>ESTJ</option>
                                <option value='ESTP'>ESTP</option>
                                <option value='INFJ'>INFJ</option>
                                <option value='INFP'>INFP</option>
                                <option value='INTJ'>INTJ</option>
                                <option value='INTP'>INTP</option>
                                <option value='ISFJ'>ISFJ</option>
                                <option value='ISFP'>ISFP</option>
                                <option value='ISTJ'>ISTJ</option>
                                <option value='ISTP'>ISTP</option>
                            </Select>


                            <Select placeholder='Select Elemental' onChange={(e) => onHandleChangeElement(e)} className="border-dropdown" icon={<DropdownComponent />}>
                                <option value=''>ALL</option>
                                <option value='EARTH'>Earth</option>
                                <option value='FIRE'>Fire</option>
                                <option value='METAL'>Metal</option>
                                <option value='MOON'>Moon</option>
                                <option value='SUN'>Sun</option>
                                <option value='WATER'>Water</option>
                                <option value='WOOD'>Wood</option>
                            </Select>

                        </div>
                        <span className="text-trick">*Trick : hover mouse on image.</span>
                        <div className="grid-control">
                            {
                                !noResult ?
                                    propRes.map((value: NFTGalleryItem, index: any) => {

                                        return (
                                            <div className="d-flex flex-column mx-auto border-item" key={index} >

                                                <div className="item-img-control" onClick={() => clickItem(value.name_id)}>
                                                    <img src={value.ungear_image_url} className="item-img"
                                                        onMouseOver={e => (e.currentTarget.src = value.gear_image_url)}
                                                        onMouseEnter={e => (e.currentTarget.src = value.gear_image_url)}
                                                        onMouseOut={e => (e.currentTarget.src = value.ungear_image_url)}

                                                    ></img>
                                                </div>

                                                <span className="item-id " onClick={() => clickItem(value.name_id)}>{value.name_id}</span>


                                                {
                                                    value.personality_level === "MEDIUM" ?
                                                        <div className="d-flex">
                                                            <img src={PSN_MEDIUM_IMG} className="icon-level"></img>
                                                            <span className="text-level medium-color">:</span>
                                                            <img src={TEXT_MEDIUM_IMG} className="img-level"></img>
                                                            <span className="text-level medium-color">{value.personality_type}</span>
                                                        </div> :
                                                        value.personality_level === "HIGH" ?
                                                            <div className="d-flex">
                                                                <img src={PSN_HIGH_IMG} className="icon-level"></img>
                                                                <span className="text-level high-color">:</span>
                                                                <img src={TEXT_HIGH_IMG} className="img-level"></img>
                                                                <span className="text-level high-color">{value.personality_type}</span>
                                                            </div> :
                                                            <div className="d-flex">
                                                                <img src={PSN_LOW_IMG} className="icon-level"></img>
                                                                <span className="text-level low-color">:</span>
                                                                <img src={TEXT_LOW_IMG} className="img-level"></img>
                                                                <span className="text-level low-color">{value.personality_type}</span>
                                                            </div>
                                                }

                                                {
                                                    value.power_day_level === "MEDIUM" ?
                                                        <div className="d-flex">
                                                            <img src={ELEM_MEDIUM_IMG} className="icon-level"></img>
                                                            <span className="text-level medium-color">:</span>
                                                            <img src={TEXT_MEDIUM_IMG} className="img-level"></img>
                                                            <span className="text-level medium-color">{value.power_day_type}</span>
                                                        </div> :
                                                        value.power_day_level === "HIGH" ?
                                                            <div className="d-flex">
                                                                <img src={ELEM_HIGH_IMG} className="icon-level"></img>
                                                                <span className="text-level high-color">:</span>
                                                                <img src={TEXT_HIGH_IMG} className="img-level"></img>
                                                                <span className="text-level high-color">{value.power_day_type}</span>
                                                            </div> :
                                                            <div className="d-flex">
                                                                <img src={ELEM_LOW_IMG} className="icon-level"></img>
                                                                <span className="text-level low-color">:</span>
                                                                <img src={TEXT_LOW_IMG} className="img-level"></img>
                                                                <span className="text-level low-color">{value.power_day_type}</span>
                                                            </div>
                                                }

                                                <div className="d-flex mx-auto button-buynow " onClick={() => clickBuyNow(value.link_opensea, value.personality_type)}>

                                                    <span className="my-auto mx-auto" >Buy Now</span>
                                                </div>
                                                <div className="d-flex mx-auto button-more-detail" onClick={() => clickItem(value.name_id)}>

                                                    <span className="my-auto mx-auto" >More detail</span>
                                                </div>


                                                {/* <span className="item-text mx-auto">{value.personality_level}</span> */}
                                            </div>

                                        )
                                    })
                                    : <div className="d-flex flex-column mx-auto">

                                        <span className="no-result-text">No Results</span>
                                    </div>
                            }
                        </div>
                        {
                            !isHideLoadmore ? <div className="d-block mx-auto button-control">
                                <button className="button-loadmore" onClick={clickLoadmore}>Load more..</button>
                            </div> : null
                        }



                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>
        </>
    )
}

export default GalleryComponent;