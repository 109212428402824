import { Select } from "@chakra-ui/react";
import React, { useState } from "react";
import HeaderComponent from "../header";
import DropdownComponent from "./dropdown";
import "./items.scss";
import MOCK_ITEM from "../../assets/images/mock-items.png";
import ApiServices from "../../services/api";
import ItemsModel, { Items } from "../../model/items.model";
import FooterComponent from "../footer";
import UtilityService from "../../utils/utility";




const ItemsComponent = () => {
    const title = "Items"
    const [type, setTypes] = useState<string>("");
    const [itemsModel, setItemsModel] = useState<ItemsModel>()
    const [isLoading, setLoading] = useState<boolean>(true);
    const [classify, setClassify] = useState<string>("");

    React.useEffect(() => {

        getItems();
    }, [])


    const clickChooseType = (val: string) => {
        UtilityService().clickSendEvent("Click", title, "Choose type " + val);
        setTypes(val);
        setLoading(true);
        ApiServices().getItems(val, classify).then((res: any) => {
            console.log(res);
            setItemsModel(res);
            setLoading(false);

        })

    }

    const getItems = async () => {

        ApiServices().getItems(type, classify).then((res: any) => {
            console.log(res);
            setItemsModel(res);
            setLoading(false);

        })
    }

    const onHandleChange = (e) => {

        if (e.target.value === "") {
            console.log("all");
            UtilityService().clickSendEvent("Click", title, "Select Class all");
            setClassify("")
        } else {
            UtilityService().clickSendEvent("Click", title, "Select Class " + e.target.value);
            setClassify(e.target.value);
        }
        setLoading(true);

        ApiServices().getItems(type, e.target.value).then((res: any) => {
            console.log(res);
            setItemsModel(res);
            setLoading(false);

        })

    }

    return (
        <>
            <div className="items container-control">
                <HeaderComponent></HeaderComponent>


                <div className="d-flex flex-column">
                    <h1 className="all-items">All Items</h1>

                    <div className="d-flex flex-column">
                        <div className="bg-control">
                            <div className="container selector-control">


                                <button className=
                                    {type === "" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("")}>All</button>
                                <button className={type === "GEAR" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("GEAR")}>GEAR</button>
                                <button className={type === "CLOTHES" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("CLOTHES")}>CLOTHES</button>
                                <button className={type === "BG" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("BG")}>BACKGROUND</button>

                            </div>
                            <div className="container selector-control-2">


                                <button className={type === "TOP" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("TOP")}>TOP</button>
                                <button className={type === "GLASSES" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("GLASSES")}>GLASSES</button>
                                <button className={type === "WING" ? "border-select" : "border-deselect"} onClick={() => clickChooseType("WING")}>WING</button>
                                <div className="border-classify-control">
                                    <Select placeholder='Select Classify' onChange={(e) => onHandleChange(e)} className="border-classify" icon={<DropdownComponent />}>
                                        <option value='COMMON'>Common</option>
                                        <option value='RARE'>Rare</option>
                                        <option value='EPIC'>Epic</option>
                                        <option value='LEGENDARY'>Legendary</option>
                                    </Select>

                                </div>


                            </div>
                        </div>

                        <div className="bg-items">

                            {
                                itemsModel?.items && !isLoading ?

                                    <div className="container items-control">
                                        {
                                            itemsModel.items.map((val: Items, index: any) => {

                                                return (

                                                    <div className="items-border d-flex flex-column" key={index}>
                                                        <img src={val.image_url} className="img-items"></img>
                                                        <div className="d-flex tags">
                                                            <span className=
                                                                {
                                                                    val.classify === "COMMON" ? "tags tags-common" :
                                                                        val.classify === "RARE" ? "tags tags-rare" :
                                                                            val.classify === "EPIC" ? "tags tags-epic" :
                                                                                "tags tags-legendary"
                                                                }
                                                            >{val.classify}</span>
                                                        </div>
                                                        <span className="types-text">{val.type}</span>
                                                        <span className="types-name">{val.name}</span>
                                                    </div>

                                                )


                                            })
                                        }

                                    </div> :

                                    itemsModel?.items && isLoading ?
                                        <div className="d-flex loading-control">

                                            <span className="loading">Loading ... </span>
                                        </div> : <div className="d-flex loading-control">

                                            <span className="loading">Loading ... </span>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                <FooterComponent isBlack={false}></FooterComponent>
            </div>

        </>
    )
}

export default ItemsComponent;