import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config';
import UtilityService from '../../utils/utility';
import TW_ACTIVE from "../../assets/images/commu-tw-active.png";
import TG_ACTIVE from "../../assets/images/commu-tg-active.png";
import FB_ACTIVE from "../../assets/images/commu-fb-active.png";
import "./index.scss";
import LOGOMONDAY from "../../assets/images/logo-with-so.png";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const FooterComponent = (isBlack: any) => {
    let navigate = useNavigate();
    function openTwitter() {
        UtilityService().clickSendEvent("Click", "Footer", "Twitter");
        window.open(config.link_social.twitter, '_blank');
    }
    function openTelegram() {
        UtilityService().clickSendEvent("Click", "Footer", "Telegram");
        window.open(config.link_social.telegram, '_blank');
    }

    function openFacebook() {
        UtilityService().clickSendEvent("Click", "Footer", "Facebook");
        window.open(config.link_social.facebook, '_blank');
    }

    function openTeam() {
        UtilityService().clickSendEvent("Click", "Footer", "Team");
        window.open(config.link_social.gitbook_team, '_blank');
    }

    function openAboutUs() {
        UtilityService().clickSendEvent("Click", "Footer", "About us");
        window.open(config.link_social.gitbook_aboutus, '_blank');
    }

    function openGitbook() {
        UtilityService().clickSendEvent("Click", "Footer", "Gitbook");
        window.open(config.link_social.gitbook, '_blank');
    }

    function gotoClaim() {
        UtilityService().clickSendEvent("Click", "Footer", "Claim Free");
        navigate("/giveaway", { replace: false });
    }

    return (

        <div className='d-flex flex-column'>
                  {/* <Button className='button-floating' onClick={gotoClaim}>FREE NFT</Button> */}
            <div className={isBlack ? "footer-control-black" :
                "footer-control"}>
                <div className="d-flex flex-column">
                    <img src={LOGOMONDAY} className="logo" />

                </div>
                <div className='d-flex flex-column'>
                <span className="follow-text">FOLLOW US</span>
                    <div className="d-flex contact-control">

                        <img className="contact-item" src={TW_ACTIVE} onClick={openTwitter}></img>
                        <img className="contact-item" src={TG_ACTIVE} onClick={openTelegram}></img>
                        <img className="contact-item" src={FB_ACTIVE} onClick={openFacebook}></img>

                    </div>
                </div>
                <div className="d-flex flex-column">

                   
                    <div className="footer-bottom-control">
                        <div className="footer-text-li"> <a href="mailto:mondayclubteam@gmail.com" className="footer-text-title">Contact Us</a></div>
                        <div className="footer-text-li-about"> <a href="" onClick={openAboutUs} className="footer-text-title">About us</a></div>
                        <div className="footer-text-li"> <a href="" onClick={openGitbook} className="footer-text-title">Gitbook</a></div>
                        <div className="footer-text-li-last"> <a href="" onClick={openTeam} className="footer-text-title">Join our team</a></div>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default FooterComponent;