const test = {
    countdown: "Mon, 13 JUNE 2022 08:00:00 GMT+0700",
    countdown_reveal: "Mon, 20 JUNE 2022 20:00:00 GMT+0700",
    countdown_private: "Mon, 19 JUNE 2022 18:00:00 GMT+0700",
    countdown_public: "Mon, 20 JUNE 2022 10:00:00 GMT+0700",
    countdown_public_end: "SUN, 26 JUNE 2022 23:59:59 GMT+0700",
    countdown_gleam_private: "Sat, 11 JUNE 2022 00:00:00 GMT+0700",
    title: "TEST",
    ga_4: "G-K9CJWH32HZ",
    ga: "UA-215714235-5",
    app: {
        home: "https://monday-club-test.web.app",
        campaign: "https://monday-club-test.web.app/campaign",
        gallery: "https://monday-club-test.web.app/gallery",
        map: "https://map-monday-club-test.web.app",
        scoutout_service: "https://scoutout.io/scoutout-system",
        launch_app: "https://app.mondayclub.io",
        personality: "https://quiz.mondayclub.io?utm_source=NFT_LANDING",
        opensea_prefix: "https://testnets.opensea.io/assets/rinkeby/0x235f48e5bcda35af115028b94b473677977069ec/"

    },

    api:{
        x_api_key: "SEBGO59guINkCWHjIbfF6eydjuMMo6IljUpTTEmnetU",
        url: 'https://asia-southeast1-monday-club-48189.cloudfunctions.net/',
        url2: 'https://asia-southeast2-monday-club-48189.cloudfunctions.net/',
        get_whitelist: 'get-whitelist-nft-test',
        check_whitelist: 'check-whitelist-nft-test',
        get_items: 'get-nft-items-test',
        get_detail_nft: 'get-nft-details-test',
        sheets_free_200: '200 NFT Campaign',
        save_whitelist: 'save-whitelist-nft-test',
        sheets_private: 'Private Sale',
        sheets_waitinglist: 'Private Sale Waiting List',
        mondayClub: 'https://monday-club-test.web.app/',
        line_authorization: 'Bearer G1CWJmSarWkX1t1fKe1bVnJg07kiNY5z2Dj1s7SKsxD',
        pinata_url: 'https://mondayclub.mypinata.cloud/ipfs',
        get_nft_reveal: 'get-nft-reveal-test',
        get_nft_gallery: 'get-nft-gallery-test',
        post_nft_reveal: 'save-nft-reveal-test'
    },
 
    link_social: {
        discord: "https://mondayclub.page.link/KPoa",
        telegram: "https://t.me/mondayclub",
        twitter: "https://twitter.com/MondayClubHQ",
        facebook: "https://www.facebook.com/MondayClub.io",
        gitbook: "https://mondayclub.gitbook.io/monday-club/",
        gitbook_team: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_aboutus: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_avartar: "https://mondayclub.gitbook.io/monday-club/products/avatar",
        gitbook_tokenomic: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/tokenomics",
        gitbook_roadmap: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/roadmap"

    },
    web3: {
        eth: {
            address: "https://etherscan.io/address/",
            transaction_hash: "https://etherscan.io/tx/",
            network: "0x1",
            id: 1,
            rpc: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
            name: "Ethereum Mainnet",
            symbol: "ETH",
            native_name: "Ethereum Mainnet",
            decimals: 18,
            block_explorer: "https://etherscan.io/",
        },

        MCNFTClaim:  {
            address: "0x68e5f9bb54b26a3bd850a09eb4a705047cef32fd"
        },
        MCNFT: {
            address: "0x87a84Fd1f96904519Ab96815f24DFbb95C0d2AaE"
        },

    }

};


const prod = {
    countdown: "Mon, 13 June 2022 11:00:00 GMT+0700",
    countdown_reveal: "Tue, 28 JUNE 2022 13:00:00 GMT+0700",
    countdown_private: "Mon, 19 JUNE 2022 19:00:00 GMT+0700",
    countdown_public: "Mon, 20 JUNE 2022 20:00:00 GMT+0700",
    countdown_public_end: "SUN, 26 JUNE 2022 23:59:59 GMT+0700",
    countdown_gleam_private: "Fri, 17 JUNE 2022 22:00:00 GMT+0700",
    title: "",
    ga_4: "G-B3CTB51NY2",
    ga: "UA-215714235-6",
    app: {
        home: "https://mondayclub.io",
        campaign: "https://mondayclub.io/campaign",
        gallery: "https://mondayclub.io/gallery",
        map: "https://map.mondayclub.io",
        scoutout_service: "https://scoutout.io/scoutout-system",
        launch_app: "https://app.mondayclub.io",
        personality: "https://quiz.mondayclub.io?utm_source=NFT_LANDING",
        opensea_prefix: "https://opensea.io/assets/ethereum/0x87a84fd1f96904519ab96815f24dfbb95c0d2aae/"

    },
    api:{
        x_api_key: "Hp20atZnU96nfp6b157jN6peAqOZdP44ggthtkgFK8s",
        url: 'https://asia-southeast1-monday-club-48189.cloudfunctions.net/',
        url2: 'https://asia-southeast2-monday-club-48189.cloudfunctions.net/',
        get_items: 'get-nft-items',
        get_detail_nft: 'get-nft-details',
        get_whitelist: 'get-whitelist-nft',
        check_whitelist: 'check-whitelist-nft',
        sheets_free_200: '200 NFT Campaign',
        sheets_private: 'Private Sale',
        sheets_waitinglist: 'Private Sale Waiting List',
        mondayClub: 'https://mondayclub.io/',
        save_whitelist: 'save-whitelist-nft',
        line_authorization: 'Bearer gctEq5DOZcFbGgojxf7mRb0O9EhOuLnNQbMUy3d8Rvz',
        pinata_url: 'https://mondayclub.mypinata.cloud/ipfs',
        get_nft_reveal: 'get-nft-reveal',
        get_nft_gallery: 'get-nft-gallery',
        post_nft_reveal: 'save-nft-reveal'
    },
 
    link_social: {
        discord: "https://mondayclub.page.link/KPoa",
        telegram: "https://t.me/mondayclub",
        twitter: "https://twitter.com/MondayClubHQ",
        facebook: "https://www.facebook.com/MondayClub.io",
        gitbook: "https://mondayclub.gitbook.io/monday-club/",
        gitbook_team: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_aboutus: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_avartar: "https://mondayclub.gitbook.io/monday-club/products/avatar",
        gitbook_tokenomic: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/tokenomics",
        gitbook_roadmap: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/roadmap"

    },
    web3: {
        eth: {
            address: "https://etherscan.io/address/",
            transaction_hash: "https://etherscan.io/tx/",
            network: "0x1",
            id: 1,
            rpc: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
            name: "Ethereum Mainnet",
            symbol: "ETH",
            native_name: "Ethereum Mainnet",
            decimals: 18,
            block_explorer: "https://etherscan.io/",
        },

        MCNFTClaim:  {
            address: "0x68e5f9bb54b26a3bd850a09eb4a705047cef32fd"
        },
        MCNFT: {
            address: "0x87a84Fd1f96904519Ab96815f24DFbb95C0d2AaE"
        },
    }
};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod 
    : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};