import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingComponent from './components/landing';
import GiveawayComponent from './components/giveaway/giveaway';
import ScrollToTop from './utils/scrollToTop';
import ItemsComponent from './components/items/items';
import DetailNFTComponent from './components/detail-nft/detail-nft';
import SaleComponent from './components/sale/sale';
import AccountComponent from './components/account/account';
import GalleryComponent from './components/gallery/gallery';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<LandingComponent />} />
          <Route path="/account" element={<AccountComponent />} />
          {/* <Route path="/giveaway" element={<GiveawayComponent />} /> */}
          <Route path="/detail-nft/:id" element={<DetailNFTComponent />} />
          {/* <Route path="/sale" element={<SaleComponent />} /> */}
          <Route path="/items" element={<ItemsComponent />} />
          <Route path="/gallery" element={<GalleryComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
