/* eslint-disable no-loop-func */
import { useDisclosure } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import PopupDesktop from "../../modal/use-desktop";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import MYSTERY_CARD_GIF from "../../assets/images/mystery-card.mp4";
import "./account.scss"
import useGlobalState from "@vighnesh153/use-global-state";
import GIVEAWAY_CARD_IMG from "../../assets/images/give-away-card.png";
import UtilityService from "../../utils/utility";
import Web3Service from "../../services/web3-service";
import config from "../../config";
import Countdown from "react-countdown";
import "../card-styles/cards.scss";
import { ethers } from "ethers";
import nft from "../../abi/nft";
import nftClaim from "../../abi/nft-claim";
import LoadingMessage from "../../modal/loading-message";
import ApiServices from "../../services/api";
import { Attribute, PinataModel } from "../../model/pinata";
import PopupReveal from "../../modal/popup-reveal";
import { useNavigate } from "react-router-dom";
const AccountComponent = () => {
    const [account, setAccount] = useGlobalState('account', "");
    const width = useWindowWidth();
    const [ownerOf, setOwnerOf] = useState(false);
    const title = "ACCOUNT";
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false
    const [isPopup, setPopup] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [totalNFT, setTotalNFT] = useState(0);
    const [isLoadingConnect, setLoadingConnect] = useState(false);
    const [isLoadingChecking, setLoadingChecking] = useState(false);
    const [isError, setError] = useState(false);
    const [openNumber, setOpenNumber] = useState(0);
    const [unopenNumber, setUnOpenNumber] = useState(0);
    const [isOpenCard, setIsOpenCard] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isReveal, setIsReveal] = useState(false);
    const [imagePath, setImagePath] = useState("");
    const [pinataModel, setPinataModel] = useState<any>([]);
    let providers: ethers.providers.Web3Provider;
    let navigate = useNavigate();
    React.useEffect(() => {
        if (isMobile || isTablet) {
        } else {
            onOpen();
            if (window.ethereum) {
                providers = new ethers.providers.Web3Provider(window.ethereum)


                if (account) {
                    connectwallet();
                }
                window.ethereum.on('accountsChanged', function (accounts) {
                    // Time to reload your interface with accounts[0]!

                    if (accounts.length === 0) {
                        setAccount("");
                    } else {
                        console.log(accounts);
                        setAccount(accounts[0])
                    }
                })

            }
        }
    }, [])
    const connectwallet = async () => {
       

        if (isMobile || isTablet) {
            setPopup(true);
            onOpen();
        } else {
            if (window.ethereum) {
                providers = new ethers.providers.Web3Provider(window.ethereum)
                setLoadingConnect(true);
                const walletAddress = await Web3Service().connectWallet();
                setAccount(walletAddress);
                setLoadingConnect(false);




                const isNetwork = await Web3Service().checkNetwork();
                if (isNetwork) {
                    checkOwner();

                } else {
                    Web3Service().handleNetworkChangeRinkeby();
                    window.ethereum.on('chainChanged', function (network: any) {
                        if (network === config.web3.eth.network) {
                            checkOwner();
                        }
                    });
                }
            } else {
                alert("Please Install Metamask");
            }
        }

    }

    const clickReveal = (index: number, id: number, image_path: string) => {
        console.log(image_path);
        setImagePath(image_path)
        setIsReveal(true);
        onOpen();
        ApiServices().postDetailCard(id, true).then((res: any) => {
            console.log(res);
            pinataModel[index].reveal = true
            console.log(pinataModel);
            setOpenNumber(openNumber + 1)
            setUnOpenNumber(unopenNumber - 1);
            setPinataModel(pinataModel => [...pinataModel, pinataModel]);
        })
    }

    const clickMenuOpenCard = (id: number) => {

        if (id === 0) {
            setIsOpenCard(false);
        } else {
            setIsOpenCard(true);
        }
    }

    const checkOwner = async () => {
        setLoadingChecking(true);
        console.log("checking");
        if (providers) {


            try {
                await providers.send("eth_requestAccounts", []);
                const signerMetaMask = providers.getSigner();
                const userAddress = await signerMetaMask.getAddress();

                console.log(signerMetaMask);
                const contractMCN = new ethers.Contract(config.web3.MCNFT.address, nft.ABI, signerMetaMask);
                try {
                    let isStop = false;
                    let startIndex = 0;
                    let opened = 0;
                    let unopened = 0;
                    let isFailed = false;
                    while (!isStop) {
                        const tx = await contractMCN.tokenOfOwnerByIndex(userAddress, startIndex)
                            .catch((error: any) => {
                                console.log(error);
                                if (!ownerOf) {
                                    setLoadingChecking(false);
                                }
                            })

                        if (tx) {
                            isFailed = true;
                            console.log(tx.toString());
                            const tx2 = await contractMCN.tokenURI(tx.toString())
                                .catch((error: any) => {

                                    isStop = true;
                                    console.log(error);
                                })

                            if (tx2) {
                                console.log(tx2);
                                UtilityService().clickSendEvent("View", title, "Own card  " + userAddress);
                                setOwnerOf(true);
                                startIndex++;
                                setTotalNFT(startIndex);


                                ApiServices().getDetailCard(tx.toString(), tx2).then((res: any) => {
                                    console.log(res);
                                    setLoadingChecking(false);
                                    if (res.reveal) {
                                        opened += 1;
                                        console.log(opened)
                                        setOpenNumber(opened);
                                    } else if (!res.reveal) {
                                        unopened += 1;
                                        setUnOpenNumber(unopened);
                                    }
                                    setPinataModel(pinataModel => [...pinataModel, res]);
                                })


                            }



                        } else {
                            if (isFailed) {
                                setLoadingChecking(false);
                            }
                            isStop = true;
                        }

                    }

                } catch (err) {
                    setLoadingChecking(false);
                    onOpen();
                    setError(true);
                    setErrorMessage("Oops! Something went wrong.");
                }

            } catch {
                setLoadingChecking(false);
                console.log("User rejected the request.")
                onOpen();
                setError(true);
                setErrorMessage("User rejected the request.");
            }
        } else {
            setLoadingChecking(false);
        }
    }

    const clickCard = (id: string, pinata: PinataModel) => {

        navigate("/detail-nft/" + pinata.token_id, { state: { pinata }, replace: false })
    }


    return (

        <>
            <div className="container-fluid container-control account">


                {
                    isReveal ? <PopupReveal onOpen={onOpen} isOpen={isOpen} onClose={onClose} image_path={imagePath} animation_url={imagePath}  ></PopupReveal> : null
                }
                {
                    !isReveal ?
                        isPopup ? <PopupDesktop onOpen={onOpen} isOpen={isOpen} onClose={onClose} ></PopupDesktop> :
                            isLoadingChecking ? <LoadingMessage onOpen={onOpen} isOpen={isLoadingChecking} onClose={onClose} message="Loading" ></LoadingMessage> : null
                        : null

                }

                <HeaderComponent></HeaderComponent>

                <div className="container own-card-control d-flex flex-column">

                    <span className="text-header">Your NFTs</span>
                    {
                        account ?

                            <div className="mx-auto  reveal-control">


                                {
                                    pinataModel.map((value: PinataModel, index: any) => {

                                        return (
                                            value.attributes.map((item: Attribute, index2: any) => {

                                                if (item.trait_type === "POWER OF DAY") {

                                                    if (item.value.includes('MOON')) {


                                                        return (
                                                            <div className="img-card-control animated"
                                                                onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card moon " >
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    } else if (item.value.includes('WOOD')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card wood ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else if (item.value.includes('EARTH')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card earth ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else if (item.value.includes('FIRE')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card fire ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else if (item.value.includes('METAL')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card metal ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else if (item.value.includes('SUN')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card sun ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else if (item.value.includes('WATER')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card water ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }

                                                    else if (item.value.includes('WOOD')) {

                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card wood ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>


                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className="img-card-control animated" onClick={() => clickCard(value.id, value)} key={index2}>
                                                                <div className="card metal ">
                                                                </div>
                                                                <video className="img-nft-in-card" autoPlay loop>
                                                                    <source src={value.animation_url} type="video/mp4"></source>
                                                                </video>
                                                                <span className="text-nft-in-card">{value.name}</span>
                                                            </div>
                                                        )
                                                    }
                                                }


                                            })

                                        )

                                    })
                                }
                            </div> : <button className="connect-wallet-button-brown mx-auto" onClick={connectwallet}>Connect Wallet</button>
                    }

                </div>
                <FooterComponent></FooterComponent>
            </div>

        </>
    )
}

export default AccountComponent;