
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import React from 'react';
import DESKTOP_IMG from "../assets/images/install-metamask-modal.png";
import "./use-desktop.scss";
import Lottie from "lottie-react";
import LoadingGif from "../assets/json/loading.json";
const LoadingMessage = ({ isOpen, onOpen, onClose, message }: any) => {
    const [size, setSize] = React.useState('xxl')

    return (

        <>
        
        <Modal closeOnOverlayClick={false} size={size} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='blackAlpha.800'
                    backdropFilter='blur(10px)' />
                <ModalContent className="rootModal background-control">
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalBody className="border-control">
                        <div className="container  d-flex flex-column loading-control my-auto">
                        <Lottie animationData={LoadingGif} loop={true} autoPlay={true} className="lottie-control" />
                            <span className="text-loading">{message}</span>
                        </div>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>
    )


}


export default LoadingMessage;