import { ethers } from "ethers";
import config from "../config";


export default function Web3Service(){





    return {
        async connectWallet(){
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                console.log("Account:", await signer.getAddress());
                return await signer.getAddress();
            } else {
                alert("Please Install Metamask");
                return "Install Metamask";
            }
        }, async checkWallet() {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const address = await provider.getSigner().getAddress();
                    if (address) {
                        return true;
                    } else {
                        return false;
                    }

                } catch {
                    return false;
                }


            } else {
                return false;

            }
        }, async checkNetwork() {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const { chainId } = await provider.getNetwork()
                console.log(chainId) // 42                
                if (chainId != config.web3.eth.id) {
                    return false;
                } else {
                    return true;
                }
            }

        }, async handleNetworkChangeRinkeby() {
            if (window.ethereum) {


                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: config.web3.eth.network }],
                    });
                } catch (switchError: any) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: config.web3.eth.network, rpcUrls: [config.web3.eth.rpc], chainName: config.web3.eth.name,
                                    nativeCurrency: {
                                        name: config.web3.eth.name,
                                        symbol: config.web3.eth.symbol,
                                        decimals: config.web3.eth.decimals
                                    },
                                    blockExplorerUrls: [config.web3.eth.block_explorer]
                                }],
                            });
                        } catch (addError) {
                        }
                    } else {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: config.web3.eth.network, rpcUrls: [config.web3.eth.rpc], chainName: config.web3.eth.name,
                                nativeCurrency: {
                                    name: config.web3.eth.name,
                                    symbol: config.web3.eth.symbol,
                                    decimals: config.web3.eth.decimals
                                },
                                blockExplorerUrls: [config.web3.eth.block_explorer]
                            }],
                        });
                    }
                }
            }
        }
    }
}