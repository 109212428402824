import "./detail-nft.scss";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import MOCK_NFT from "../../assets/images/detail/mock.mp4";
import BACK_ARROW from "../../assets/images/detail/arrow-left.png";
import ReactPlayer from "react-player";

import BG_COMMON from "../../assets/images/detail/common/bg-common.png";
import CLOTHES_COMMON from "../../assets/images/detail/common/clothes-common.png";
import EXPRESSION_COMMON from "../../assets/images/detail/common/expression-common.png";
import GEAR_COMMON from "../../assets/images/detail/common/gear-common.png";
import GLASSES_COMMON from "../../assets/images/detail/common/glasses-common.png";
import TOP_COMMON from "../../assets/images/detail/common/top-common.png";
import WING_COMMON from "../../assets/images/detail/common/wing-common.png";

import BG_RARE from "../../assets/images/detail/rare/bg-rare.png";
import CLOTHES_RARE from "../../assets/images/detail/rare/clothes-rare.png";
import EXPRESSION_RARE from "../../assets/images/detail/rare/expression-rare.png";
import GEAR_RARE from "../../assets/images/detail/rare/gear-rare.png";
import GLASSES_RARE from "../../assets/images/detail/rare/glasses-rare.png";
import TOP_RARE from "../../assets/images/detail/rare/top-rare.png";
import WING_RARE from "../../assets/images/detail/rare/wing-rare.png";

import BG_EPIC from "../../assets/images/detail/epic/bg-epic.png";
import CLOTHES_EPIC from "../../assets/images/detail/epic/clothes-epic.png";
import EXPRESSION_EPIC from "../../assets/images/detail/epic/expression-epic.png";
import GEAR_EPIC from "../../assets/images/detail/epic/gear-epic.png";
import GLASSES_EPIC from "../../assets/images/detail/epic/glasses-epic.png";
import TOP_EPIC from "../../assets/images/detail/epic/top-epic.png";
import WING_EPIC from "../../assets/images/detail/epic/wing-epic.png";

import BG_LEGEND from "../../assets/images/detail/legendary/bg-legend.png";
import CLOTHES_LEGEND from "../../assets/images/detail/legendary/clothes-legend.png";
import EXPRESSION_LEGEND from "../../assets/images/detail/legendary/expression-legend.png";
import GEAR_LEGEND from "../../assets/images/detail/legendary/gear-legend.png";
import GLASSES_LEGEND from "../../assets/images/detail/legendary/glasses-legend.png";
import TOP_LEGEND from "../../assets/images/detail/legendary/top-legend.png";
import WING_LEGEND from "../../assets/images/detail/legendary/wing-legend.png";

import PERSONALITY_ICON from "../../assets/images/detail/personality-icon.png";
import ELEMENT_ICON from "../../assets/images/detail/element-icon.png";
import CLASSIFY_CHART from "../../assets/images/detail/classify-chart.png";
import ApiServices from "../../services/api";
import { DetailNFTModel, Element } from "../../model/detail-nft.model";
import OPENSEA_BLUE from "../../assets/images/opensea-white.png";
import { PinataModel } from "../../model/pinata";
import { ethers } from "ethers";
import config from "../../config";
import nft from "../../abi/nft";
import Web3Service from "../../services/web3-service";
export enum ENUM_TYPES_ELEMENT {

    CLOTHES = "CLOTHES",
    GEAR = "GEAR",
    BG = "BG",
    GLASSES = "GLASSES",
    EXPRESSION = "EXPRESSION",
    TOP = "TOP",
    WING = "WING"
}

export enum ENUM_CLASSIFY {

    COMMON = "COMMON",
    EPIC = "EPIC",
    RARE = "RARE",
    LEGENDARY = "LEGENDARY"
}

const DetailNFTComponent = () => {
    let { id } = useParams();
    const { state } = useLocation();
    const otherParams = state as Object;
    const [bgImg, setBgImg] = useState<string>(BG_COMMON);
    const [clothesImg, setClothesImg] = useState<string>(CLOTHES_COMMON);
    const [expressionImg, setExpressionImg] = useState<string>(EXPRESSION_COMMON);
    const [gearImg, setGearImg] = useState<string>(GEAR_COMMON);
    const [glassesImg, setGlassesImg] = useState<string>(GLASSES_COMMON);
    const [topImg, setTopImg] = useState<string>(TOP_COMMON);
    const [wingImg, setWingImg] = useState<string>(WING_COMMON);
    const [pinataModel, setPinataModel] = useState<PinataModel>(Object);
    const [propRes, setPropres] = useState<DetailNFTModel>();
    const [ownerOf, setOwnerOf] = useState("");
    const [isLoadDetail, setIsLoadDetail] = useState(false);
    let navigate = useNavigate();
    let providers: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider;
    const clickBack = () => {
        navigate(-1);

    }

   

    const getDetailNFT = async () => {

        if(window.ethereum){
            providers = new ethers.providers.Web3Provider(window.ethereum)
        }else{
            providers = new ethers.providers.JsonRpcProvider(config.web3.eth.rpc);
        }
        console.log(providers);
          
            const contractMCN = new ethers.Contract(config.web3.MCNFT.address, nft.ABI, providers);
            try {
                const tx = await contractMCN.ownerOf(id);
                const tx2 = await contractMCN.tokenURI(id);

                console.log(tx);
                console.log(tx2);
                setOwnerOf(tx);

                ApiServices().getDetailCard(id+"", tx2).then((res:any) => {
                    console.log(res);
                    setPinataModel(res);
                    ApiServices().getDetailNFT(res.id + "").then((res: DetailNFTModel) => {
                        console.log(res);
                        setIsLoadDetail(true);
                        setPropres(res);
                        res.elements.map((res: Element, index: any) => {
        
                            if (res.type === ENUM_TYPES_ELEMENT.BG) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setBgImg(BG_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setBgImg(BG_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setBgImg(BG_EPIC)
                                } else {
                                    setBgImg(BG_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.GEAR) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setGearImg(GEAR_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setGearImg(GEAR_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setGearImg(GEAR_EPIC)
                                } else {
                                    setGearImg(GEAR_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.CLOTHES) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setClothesImg(CLOTHES_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setClothesImg(CLOTHES_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setClothesImg(CLOTHES_EPIC)
                                } else {
                                    setClothesImg(CLOTHES_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.EXPRESSION) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setExpressionImg(EXPRESSION_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setExpressionImg(EXPRESSION_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setExpressionImg(EXPRESSION_EPIC)
                                } else {
                                    setExpressionImg(EXPRESSION_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.GLASSES) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setGlassesImg(GLASSES_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setGlassesImg(GLASSES_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setGlassesImg(GLASSES_EPIC)
                                } else {
                                    setGlassesImg(GLASSES_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.TOP) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setTopImg(TOP_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setTopImg(TOP_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setTopImg(TOP_EPIC)
                                } else {
                                    setTopImg(TOP_LEGEND)
                                }
                            } else if (res.type === ENUM_TYPES_ELEMENT.WING) {
                                if (res.classify === ENUM_CLASSIFY.COMMON) {
                                    setWingImg(WING_COMMON)
                                } else if (res.classify === ENUM_CLASSIFY.RARE) {
                                    setWingImg(WING_RARE)
                                } else if (res.classify === ENUM_CLASSIFY.EPIC) {
                                    setWingImg(WING_EPIC)
                                } else {
                                    setWingImg(WING_LEGEND)
                                }
                            }
                        })
                    })
                })
            } catch {

                console.log("ERROR");

            }
            
    }

    const clickOpensea = () =>{
        window.open(config.app.opensea_prefix + pinataModel.token_id)
    }
    function convertAddress(wallet: string) {
        const addressConvert = wallet.substring(0, 4) +
            "......" +
            wallet.substring(38, wallet.length);
        return addressConvert;
    }
    React.useEffect(() => {

        console.log(id);
        checkNetwork();
       
      
    }, [])

    const checkNetwork = async() => {
        if(window.ethereum){
            const isNetwork = await Web3Service().checkNetwork();
            if (isNetwork) {
                getDetailNFT();

            } else {
                Web3Service().handleNetworkChangeRinkeby();
                window.ethereum.on('chainChanged', function (network: any) {
                    if (network === config.web3.eth.network) {
                        getDetailNFT();
                    }
                });
            }
        }else{
            getDetailNFT();
        }
    }

    return (

        <>
            <div className="container-fluid container-control giveaway ">
                <HeaderComponent></HeaderComponent>

                <div className="detail-nft">


                    <div className="page-control">

                        <div className="container grid-control">
                            <div className="d-lg-none d-flex back-control" onClick={clickBack}>
                                <img src={BACK_ARROW} className="back-arrow my-auto"></img>
                                <span className="back-text my-auto">Back</span>
                            </div>
                            <div className="left-control">
                                <ReactPlayer
                                    url={pinataModel.animation_url}
                                    playing={true}
                                    muted={true}
                                    loop={true}
                                    className='nft-border'
                                    playsinline

                                />
                                <div className="d-block mx-auto">
                                    <div className="button-opensea d-flex" onClick={clickOpensea}>
                                        <div className="d-flex mx-auto">
                                            <img src={OPENSEA_BLUE} className="img-opensea my-auto"></img>
                                            <span className="text-opensea">Buy now</span>
                                        </div></div>
                                </div>
                            </div>

                            <div className="right-control">

                                <div className="d-lg-flex d-none back-control" onClick={clickBack}>
                                    <img src={BACK_ARROW} className="back-arrow my-auto"></img>
                                    <span className="back-text my-auto">Back</span>
                                </div>

                                <div className="box-control d-flex flex-column">
                                    <div className=" d-flex squad-text-control">

                                        <span className="text-header my-auto">
                                            Monday Squad :
                                        </span>
                                        <span className="text-nanum-header my-auto">NOW HIRING</span>
                                    </div>

                                    <span className="text-desc">1,000 Monday Squad members working together to make Monday Great Again! Exclusive NFTs from Monday Club designed by combining the 16 Personalities & 7 Elements.
                                        Which team are you?</span>

                                    <div className="d-flex">
                                        <div className="id-control">{pinataModel.name}</div>
                                    </div>

                                    <span className="text-header">Owner address :
                                        <span className="text-yellow-link">{convertAddress(ownerOf)}</span>
                                    </span>
                                </div>

                                <div className="box-control d-flex flex-column">
                                    {
                                        isLoadDetail ?
                                            <div className="d-flex flex-column">
                                                <span className="text-header mr-auto my-auto d-flex">
                                                    Properties :
                                                </span>
                                                <div className="divider-line"></div>

                                                <div className="d-flex element-control">
                                                    <img src={PERSONALITY_ICON} className="icon-img my-auto"></img>
                                                    <span className="text-element my-auto common-color">16 PERSONALITIES :</span>
                                                    <span className="text-name-element my-auto">{propRes?.personality_type} | {propRes?.personality_level}</span>
                                                </div>

                                                <div className="d-flex element-control">
                                                    <img src={ELEMENT_ICON} className="icon-img my-auto"></img>
                                                    <span className="text-element my-auto common-color">ELEMENTS :</span>
                                                    <span className="text-name-element my-auto">{propRes?.power_day_type} | {propRes?.power_day_level}</span>
                                                </div>
                                                <div className="divider-line"></div>
                                                {
                                                    propRes?.elements.map((res: Element, index: any) => {

                                                        return (


                                                            <div className="d-flex flex-column" key={index}>
                                                                {
                                                                    res.type === ENUM_TYPES_ELEMENT.CLOTHES ?
                                                                        <div className="element-control">
                                                                            <img src={clothesImg} className="icon-img my-auto"></img>
                                                                            <span className={
                                                                                res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                    "text-element my-auto common-color" :
                                                                                    res.classify === ENUM_CLASSIFY.RARE ?
                                                                                        "text-element my-auto rare-color" :
                                                                                        res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                            "text-element my-auto epic-color" :
                                                                                            "text-element my-auto legend-color"
                                                                            }>CLOTHES :</span>
                                                                            <span className="text-name-element my-auto">{res.name}</span>
                                                                        </div> :

                                                                        res.type === ENUM_TYPES_ELEMENT.GEAR ?
                                                                            <div className="element-control">
                                                                                <img src={gearImg} className="icon-img my-auto"></img>
                                                                                <span className={
                                                                                    res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                        "text-element my-auto common-color" :
                                                                                        res.classify === ENUM_CLASSIFY.RARE ?
                                                                                            "text-element my-auto rare-color" :
                                                                                            res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                "text-element my-auto epic-color" :
                                                                                                "text-element my-auto legend-color"
                                                                                }>GEAR :</span>
                                                                                <span className="text-name-element my-auto">{res.name}</span>
                                                                            </div> :

                                                                            res.type === ENUM_TYPES_ELEMENT.BG ?
                                                                                <div className="element-control">
                                                                                    <img src={bgImg} className="icon-img my-auto"></img>
                                                                                    <span className={
                                                                                        res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                            "text-element my-auto common-color" :
                                                                                            res.classify === ENUM_CLASSIFY.RARE ?
                                                                                                "text-element my-auto rare-color" :
                                                                                                res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                    "text-element my-auto epic-color" :
                                                                                                    "text-element my-auto legend-color"
                                                                                    }>BACKGROUND :</span>
                                                                                    <span className="text-name-element my-auto">{res.name}</span>
                                                                                </div> :

                                                                                res.type === ENUM_TYPES_ELEMENT.EXPRESSION ?
                                                                                    <div className="element-control">
                                                                                        <img src={expressionImg} className="icon-img my-auto"></img>
                                                                                        <span className={
                                                                                            res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                                "text-element my-auto common-color" :
                                                                                                res.classify === ENUM_CLASSIFY.RARE ?
                                                                                                    "text-element my-auto rare-color" :
                                                                                                    res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                        "text-element my-auto epic-color" :
                                                                                                        "text-element my-auto legend-color"
                                                                                        }>EXPRESSION :</span>
                                                                                        <span className="text-name-element my-auto">{res.name}</span>
                                                                                    </div> :

                                                                                    res.type === ENUM_TYPES_ELEMENT.GLASSES ?
                                                                                        <div className="element-control">
                                                                                            <img src={glassesImg} className="icon-img my-auto"></img>
                                                                                            <span className={
                                                                                                res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                                    "text-element my-auto common-color" :
                                                                                                    res.classify === ENUM_CLASSIFY.RARE ?
                                                                                                        "text-element my-auto rare-color" :
                                                                                                        res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                            "text-element my-auto epic-color" :
                                                                                                            "text-element my-auto legend-color"
                                                                                            }>GLASSES :</span>
                                                                                            <span className="text-name-element my-auto">{res.name}</span>
                                                                                        </div> :

                                                                                        res.type === ENUM_TYPES_ELEMENT.TOP ?
                                                                                            <div className="element-control">
                                                                                                <img src={topImg} className="icon-img my-auto"></img>
                                                                                                <span className={
                                                                                                    res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                                        "text-element my-auto common-color" :
                                                                                                        res.classify === ENUM_CLASSIFY.RARE ?
                                                                                                            "text-element my-auto rare-color" :
                                                                                                            res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                                "text-element my-auto epic-color" :
                                                                                                                "text-element my-auto legend-color"
                                                                                                }>TOP :</span>
                                                                                                <span className="text-name-element my-auto">{res.name}</span>
                                                                                            </div> :

                                                                                            res.type === ENUM_TYPES_ELEMENT.WING ?
                                                                                                <div className="element-control">
                                                                                                    <img src={wingImg} className="icon-img my-auto"></img>
                                                                                                    <span className={
                                                                                                        res.classify === ENUM_CLASSIFY.COMMON ?
                                                                                                            "text-element my-auto common-color" :
                                                                                                            res.classify === ENUM_CLASSIFY.RARE ?
                                                                                                                "text-element my-auto rare-color" :
                                                                                                                res.classify === ENUM_CLASSIFY.EPIC ?
                                                                                                                    "text-element my-auto epic-color" :
                                                                                                                    "text-element my-auto legend-color"
                                                                                                    }>WING :</span>
                                                                                                    <span className="text-name-element my-auto">{res.name}</span>
                                                                                                </div> : null

                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className="divider-line"></div>
                                                <span className="text-header mr-auto my-auto d-flex">Gear & Ungear</span>
                                                <div className="d-flex">
                                                    <img src={propRes?.gear_image_url} className="image-ungear"></img>
                                                    <img src={propRes?.ungear_image_url} className="image-ungear"></img>
                                                </div>
                                            </div> :

                                            <span className="text-loading">Loading Properties....</span>
                                    }


                                </div>
                                <img src={CLASSIFY_CHART} className="classify-chart-img"></img>
                            </div>
                        </div>

                    </div>
                </div>

                <FooterComponent></FooterComponent>

            </div>
        </>
    )


}


export default DetailNFTComponent;