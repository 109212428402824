import { Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";
import MONDAYTOKEN from "../../assets/images/monday-token.png";
import MENU_IMG from "../../assets/images/menu-nav.png";
import BTN_OPENSEA from "../../assets/images/btn-opensea.png";
import BTN_DISCORD from "../../assets/images/btn-discord.png";
import BTN_TWITTER from "../../assets/images/btn-twitter.png";
import CLOSE_IMG from "../../assets/images/close.png";
import config from "../../config";
import React from "react";
import UtilityService from "../../utils/utility";
import { useNavigate } from "react-router-dom";
import LOGOMONDAY from "../../assets/images/logo-monday.png";
import { useDisclosure } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import Web3Service from "../../services/web3-service";
import useGlobalState from "@vighnesh153/use-global-state";
const HeaderComponent = () => {
    let navigate = useNavigate();

    const [isOpenNav, setOpen] = React.useState(false);
    const width = useWindowWidth();
    const title = "SALE";
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false
    const [isPopup, setPopup] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [account, setAccount] = useGlobalState('account', "");
    const connectWallet = async () => {
        if (isMobile || isTablet) {
            setPopup(true);
            onOpen();
        } else {
            if(window.ethereum){
                const walletAddress = await Web3Service().connectWallet();
                setAccount(walletAddress);
    
    
                const isNetwork = await Web3Service().checkNetwork();
                if (isNetwork) {
    
    
                } else {
                    Web3Service().handleNetworkChangeRinkeby();
                    window.ethereum.on('chainChanged', function (network: any) {
                        if (network === config.web3.eth.network) {
    
                        }
                    });
                }
            }else{
                alert("Please Install Metamask");
            }
          
        }

    }

    React.useEffect(() => {

    }, [])

    const clickNav = () => {

        if (isOpenNav) {
            UtilityService().clickSendEvent("Click", "Header", "Close Menu ")
            setOpen(false);
        } else {
            UtilityService().clickSendEvent("Click", "Header", "Open Menu ")
            setOpen(true);
        }
    }



    const clickDiscord = () => {
        UtilityService().clickSendEvent("Click", "Header", "Discord ")
        window.open(config.link_social.discord, "_blank")
    }

    const clickTwitter = () => {
        UtilityService().clickSendEvent("Click", "Header", "Twitter ")
        window.open(config.link_social.twitter, "_blank")
    }

    const clickHome = () => {
        UtilityService().clickSendEvent("Click", "Header", "Home ")
        navigate("/", { replace: false });

    }
    const clickBranded = () => {
        UtilityService().clickSendEvent("Click", "Header", "Branded Campaigns")
        window.open("https://mondayclub.io/campaign")
        clickNav();
    }
    const clickGallery = () => {
        UtilityService().clickSendEvent("Click", "Header", "SQUAD GALLERY")
        navigate("/gallery", { replace: false });
        clickNav();
    }
    const clickGiveaway = () => {
        UtilityService().clickSendEvent("Click", "Header", "Giveaway ")
        navigate("/giveaway", { replace: false });
        clickNav();
    }

    const clickSale = () => {
        UtilityService().clickSendEvent("Click", "Header", "Sale ")
        navigate("/sale", { replace: false });
        clickNav();
    }
    const clickAccount = () => {
        UtilityService().clickSendEvent("Click", "Header", "Account")
        navigate("/account", { replace: false });
        clickNav();
    }
    const clickItems = () => {
        UtilityService().clickSendEvent("Click", "Header", "Items")
        navigate("/items", { replace: false });
        clickNav();
    }
    const clickPersonalityTest = () => {
        UtilityService().clickSendEvent("Click", "Header", "PERSONALITY TEST")
        window.open(config.app.personality, "_blank");
        clickNav();
    }
    const clickCard = () => {
        UtilityService().clickSendEvent("Click", "Header", "Account")
        navigate("/account", { replace: false });
        clickNav();
    }
    const clickMonday = () => {
        UtilityService().clickSendEvent("Click", "Header", "Monday Club Landing")
        window.open(config.api.mondayClub, "_blank");
    }
    const clickOpensea = () => {
        UtilityService().clickSendEvent("Click", "Header", "Opensea")
        window.open("https://opensea.io/collection/monday-club-nft", "_blank");
    }
    function convertAddress(wallet: string) {
        const addressConvert = wallet.substring(0, 5) +
            "......" +
            wallet.substring(37, wallet.length);
        return addressConvert;
    }

    return (
        <div className="container header-control">
            <div className="grid-control">
                <div className="d-flex logo-control " onClick={clickHome}>
                    <img src={MONDAYTOKEN} className="logo-monday"></img>
                    <span className="monday-squad">MONDAY SQUAD</span>
                </div>

                <div className="d-flex grid-right-control">
                    <div className="d-none d-md-flex my-auto btn-social-control  ml-auto">
                        <img src={BTN_OPENSEA} className="btn-social" onClick={clickOpensea}></img>
                        <img src={BTN_DISCORD} className="btn-social" onClick={clickDiscord}></img>
                        <img src={BTN_TWITTER} className="btn-social" onClick={clickTwitter}></img>
                    </div>

                    {
                        account ?
                            <button className="d-none d-md-flex connect-wallet-button" onClick={clickAccount}>
                                <span className="mx-auto my-auto text-address">{convertAddress(account)}
                                </span> </button> : null
                            // <button className="d-none d-md-flex connect-wallet-button" onClick={connectWallet}>

                            //     <span className="mx-auto my-auto">Connect Wallet
                            //     </span>

                            // </button>

                    }

                    <img src={MENU_IMG} className="menu-img" onClick={clickNav}></img>
                </div>
            </div>
            <div className={!isOpenNav ? "sidenav" : "sidenav-active"}>
                <div className="d-flex flex-column sidenav-control">
                    <img src={CLOSE_IMG} className="close-img" onClick={clickNav}></img>
                    <span className="text-menu" onClick={clickHome}>HOME</span>
                    <span className="text-menu" onClick={clickCard}>YOUR CARD
                    </span>
                    <span className="text-menu " onClick={clickGallery}>SQUAD GALLERY
                    </span>
                    <span className="text-menu " onClick={clickBranded}>BRANDED CAMPAIGNS
                    </span>
                    {/* <span className="text-menu " onClick={clickGiveaway}>GIVEAWAY
                    </span>
                    <span className="text-menu " onClick={clickSale}>SALE
                    </span> */}
                    <span className="text-menu" onClick={clickItems}>ITEMS
                    </span>
                    <span className="text-menu" onClick={clickPersonalityTest}>PERSONALITY TEST
                    </span>
                    <div className="d-flex d-md-none my-auto btn-social-control  mx-auto">
                        <img src={BTN_OPENSEA} className="btn-social" onClick={clickOpensea}></img>
                        <img src={BTN_DISCORD} className="btn-social" onClick={clickDiscord}></img>
                        <img src={BTN_TWITTER} className="btn-social" onClick={clickTwitter}></img>
                    </div>

                    <div className="d-flex flex-column logo-full-monday-control">
                        <span className="coming-soon">Powered by</span>
                        <img src={LOGOMONDAY} className="logo-full-monday mx-auto" onClick={clickMonday}></img>
                    </div>

                    {/* <button className="d-flex d-md-none connect-wallet-button">
                        
                       <span className="mx-auto my-auto">Connect Wallet
                           </span> 
                        
                        </button> */}
                </div>
            </div>
        </div>
    )

}

export default HeaderComponent;
function NavLinkOutsideLaunchApp({ path, title, option }) {
    return (
        <Button className="d-flex button-launch-app">
            <a href={path} target="_blank"><span>{title}</span></a>
        </Button>

    )
}


function NavLinkOutside({ path, title, option }) {
    return (
        <a href={path} target="_blank" className="nav-link navbar-sm link"><span>{title}</span></a>
    )
}
function NavLink({ path, title }) {
    return (
        <Link to={path} className="nav-link navbar-sm link">{title}</Link>
    )
}

function ButtonConnectWallet({ connectWallet }) {
    return (
        <button className="btn btn-connect" onClick={connectWallet}>Connect wallet</button>
    )
}