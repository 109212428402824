
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import React from 'react';
import DESKTOP_IMG from "../assets/images/install-metamask-modal.png";
import "./use-desktop.scss";
const PopupDesktop = ({ isOpen, onOpen, onClose }: any) => {
    const [size, setSize] = React.useState('xxl')

    return (

        <>
        
        <Modal closeOnOverlayClick={false} size={size} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='blackAlpha.800'
                    backdropFilter='blur(10px)' />
                <ModalContent className="rootModal background-control">
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton color={'white'} className="close-button" onClick={onClose} />
                    <ModalBody className="border-control">
                        <div className="container  d-flex flex-column loading-control my-auto">
                            <img src={DESKTOP_IMG} className="img-desktop" ></img>
                            <span className="text-loading">Please use desktop to connect with MetaMask.</span>
                        </div>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>
    )


}


export default PopupDesktop;