
import ICON_DROPDOWN from "../../assets/images/icon-dropdown.png";
import "./items.scss";
const DropdownComponent = () => {



    return (
        <img src={ICON_DROPDOWN} className="dropdown-img" ></img>
    )
}

export default DropdownComponent;